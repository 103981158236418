import { APP_TITLE } from '@app/core/constants/apis-list.constant';

export const MetaTags = [
  {
    url: '',
    title: APP_TITLE,
    keywords: APP_TITLE,
    description: APP_TITLE,
    author: APP_TITLE,
  },
];

export const APP = {
  DEFAULT_TIMEZONE: 'utc',
  DEFAULT_CACHE_TTL: 36000000,
  DATETIME_FORMAT: 'DD/MM/yyyy HH:mm',
  DATE_FORMAT: 'DD/MM/yyyy', 
  API_DATE_FORMAT: 'MM/DD/yyyy', 
  TIME_FORMAT: 'HH:mm',
}

export const CALENDER_FROM: any = {
  service_roster_planning:"service-roster-planning",
  employee_engagement:"employee-engagement"
};