import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { IAppConfig } from '../interfaces/config.model';

@Injectable({
  providedIn: 'root',
})
export class AppConfigurations {
  config: IAppConfig = {
    name: 'SID',
    title: 'SID',
    version: '',
    //  mapKey: environment.env.NG_APP_MAP_KEY,
    apiUrl: environment.apiUrl || 'https://sid-stg-be.ideatolife.me',
    s3BucketUrl: environment.s3BucketUrl || 'https://sid-stg-public.s3.amazonaws.com/',

    environment: 'staging',
  };

  constructor() {
    if (environment.development) {
      this.config.environment = 'development';
    }
    if (environment.uat) {
      this.config.environment = 'uat';
      this.config.apiUrl =
        environment.apiUrl || 'https://sid-stg-be.ideatolife.me';
    }
    if (environment.production) {
      this.config.environment = 'production';
    }
  }

  /**
   * Returns the configuration object.
   *
   * @return {any} The configuration object.
   */
  getConfig(): any {
    return this.config;
  }
}
