import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { BaseNetworkService } from './base-network.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService extends BaseNetworkService {
  /**
   * Initializes the constructor of the class with the provided injector.
   *
   * @param {Injector} injector - The injector used to retrieve dependencies.
   */
  constructor(private injector: Injector) {
    super(injector);
  }

  /**
   * Sends an HTTP request with the specified method, API link, body, headers, and options.
   *
   * @param {string} method - The HTTP method to use for the request.
   * @param {string} apiLink - The API link to send the request to.
   * @param {any} [body] - The request body.
   * @param {HttpHeaders} [headers=this.formDataHeaders] - The headers to include in the request.
   * @param {boolean} [showMessage=true] - Whether to show a message for the response.
   * @param {boolean} [htmlResponse=false] - Whether to expect an HTML response.
   * @param {boolean} [arrayBuffer=false] - Whether to expect an ArrayBuffer response.
   * @param {boolean} [blob=false] - Whether to expect a Blob response.
   * @return {Observable<any>} An observable that emits the response from the request.
   */
  public requestEntity(
    method: string,
    apiLink: string,
    body?: any,
    headers: HttpHeaders = this.formDataHeaders,
    showMessage: boolean = true,
    htmlResponse: boolean = false,
    arrayBuffer: boolean = false,
    blob: boolean = false,
  ) {
    let params: HttpParams = new HttpParams();
    if (method === 'GET' && Object.keys(body || {}).length > 0) {
      for (let key in body) {
        if (body.hasOwnProperty(key)) {
           if (Array.isArray(body[key])) {
            body[key].forEach((u: any) => {
              params = params.append(key, u);
            });
          } else {
            params = params.append(key, body[key]);
          }
        }
      }
    }
    let requestOptions: any = {
      body,
      headers,
      params,
      // params: method === 'GET' && Object.keys(body || {}).length > 0 ? new HttpParams({ fromObject: body || {} }) : {},
      observe: 'response',
    };

    // Set response type based on options
    if (htmlResponse) {
      requestOptions.responseType = 'text';
    }
    if (arrayBuffer) {
      requestOptions.responseType = 'arraybuffer';
      requestOptions.observe = 'response';
    }
    if (blob) {
      requestOptions.responseType = 'blob';
    }

    return this.http.request(method, apiLink, requestOptions).pipe(
      map((response: any) => {
        try {
          const isError = (response?.status >= 400 && response?.status <= 500);
          if (response.message && showMessage && response.message?.toLowerCase() !== 'success') {
            this.showMessage(response.message, isError ? 'error' : 'success');
          }
          return response;
        } catch (e) {
          return response;
        }
      }),
      catchError((e: any) => throwError(() => showMessage ? this.handleErrorMessages(e.error) : e.error)),
    );
  }
}
