import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { range } from 'rxjs/internal/observable/range';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from './local-storage.service';
import * as CONSTANT_LIST from '../constants/system.constant';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BaseNetworkService {
  public localService: LocalStorageService;
  public toastService: ToastrService;
  private translate: TranslateService;
  protected router: Router;
  protected http: HttpClient;
  protected constantList = CONSTANT_LIST;
  public user: any = null;
  public systemDefaults: any = null;
  private _headers: HttpHeaders = new HttpHeaders();
  private _formDataHeaders: HttpHeaders = new HttpHeaders();
  private _multipartFormDataHeaders: any;

  /**
   * Initializes the constructor of the class with the provided injector.
   *
   * @param {Injector} injector - The injector used to retrieve dependencies.
   */
  constructor(injector: Injector) {
    this.localService = injector.get(LocalStorageService);
    this.toastService = injector.get(ToastrService);
    this.translate = injector.get(TranslateService);
    this.router = injector.get(Router);
    this.http = injector.get(HttpClient);
    this.initHeaders();
    this.initFormDataHeaders();
    this.initMultiPartFormDataHeaders();
  }

  /**
   * Initializes the headers for the HTTP request.
   *
   * This function sets the 'Content-Type' and 'Accept' headers for the request.
   * If a token is available, it also sets the 'Authorization' header.
   *
   * @return {void} This function does not return a value.
   */
  initHeaders(): void {
    const token = this.localService.getToken();
    this._headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Accept', '*/*');
    if (token) {
      this._headers = this._headers.set('Authorization', `${token}`);
    }
  }

  /**
   * Initializes the form data headers for the HTTP request.
   *
   * This function sets the 'Content-Type' and 'Accept' headers for the form data request.
   * If a token is available, it also sets the 'Authorization' header.
   *
   * @return {void} This function does not return a value.
   */
  initFormDataHeaders(): void {
    const token = this.localService.getToken();
    this._formDataHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
    if (token) {
      this._formDataHeaders = this._formDataHeaders.set('Authorization', `${token}`);
    }
  }

  /**
   * Initializes the multipart form data headers for the HTTP request.
   *
   * This function sets the 'Accept' header for the multipart form data request.
   * If a token is available, it also sets the 'Authorization' header.
   *
   * @return {void} This function does not return a value.
   */
  initMultiPartFormDataHeaders(): void {
    // HttpHeader class are immutable objects.
    const token = this.localService.getToken();
    this._multipartFormDataHeaders = {
      //'Content-Type': 'undefined',
      Accept: '*/*',
    };
    if (token) {
      this._multipartFormDataHeaders['Authorization'] = token;
    }
  }

  /**
   * Returns the HTTP headers for the request.
   *
   * If the headers have not been initialized, this function initializes them by calling the `initHeaders` method.
   *
   * @return {HttpHeaders} The HTTP headers for the request.
   */
  get headers(): HttpHeaders {
    if (!this._headers) {
      this.initHeaders();
    }
    return this._headers;
  }

  /**
   * Sets the value of the headers property.
   *
   * @param {HttpHeaders} value - The new value for the headers property.
   */
  set headers(value: HttpHeaders) {
    this._headers = value;
  }

  /**
   * Returns the form data headers for the HTTP request.
   *
   * If the form data headers have not been initialized, this function initializes them by calling the `initFormDataHeaders` method.
   *
   * @return {HttpHeaders} The form data headers for the request.
   */
  get formDataHeaders(): HttpHeaders {
    if (!this._formDataHeaders) {
      this.initFormDataHeaders();
    }
    return this._formDataHeaders;
  }

  /**
   * Sets the value of the formDataHeaders property.
   *
   * @param {HttpHeaders} value - The new value for the formDataHeaders property.
   */
  set formDataHeaders(value: HttpHeaders) {
    this._formDataHeaders = value;
  }

  /**
   * Returns the multipart form data headers for the HTTP request.
   *
   * If the multipart form data headers have not been initialized, this function initializes them by calling the `initMultiPartFormDataHeaders` method.
   *
   * @return {any} The multipart form data headers for the request.
   */
  get multiPartFormDataHeaders(): any {
    if (!this._multipartFormDataHeaders) {
      this.initMultiPartFormDataHeaders();
    }

    return this._multipartFormDataHeaders;
  }

  /**
   * Parses the given JSON response and returns it as any type.
   *
   * @param {any} json - The JSON response to be parsed.
   * @return {any} The parsed JSON response.
   */
  parseResponse(json: any): any {
    return json as any;
  }

  /**
   * The following method is used to get the error messages from the response
   * @_param json
   * @_returns {any}
   */
  getErrorMessages(json: any): any {
    const errors = [];
    if (json) {
      if (json?.errors || json.validation_errors) {
        range(0, Object.keys(json?.errors).length || Object.keys(json.validation_errors).length).subscribe({
          next: index => {
            errors.push(json?.errors[Object.keys(json?.errors)[index]]?.error || json.validation_errors[Object.keys(json.validation_errors)[index]].error);
          },
          error: err => {
            errors.push(err);
          },
          /**
           * Completes the operation and pushes the JSON message to the errors array if it is not empty and the JSON message exists.
           *
           * @return {void} This function does not return a value.
           */
          complete: () => {
            if (!errors.length && json?.message) {
              errors.push(json?.message);
            }
          },
        });
      }

      // check for access_denied error
      if (json.message === this.constantList.DEFAULT_ACCESS_DENIED_CODE) {
        errors.push(this.constantList.DEFAULT_ACCESS_DENIED_MESSAGE);
      } else if (json.message) {
        errors.push(json.message);
      } else if (errors.length === 0) {
        errors.push(this.constantList.DEFAULT_ERROR_MESSAGE);
      }
    }
    return errors;
  }

  /**
   * Handles error messages from the API response.
   *
   * @param {any} json - The API response JSON.
   */
  handleErrorMessages(json: any) {
    if (json?.statusCode === 500) {
      this.showMessage('Internal Server Error', 'error');
    } else {
      if (json !== null) {
        if (json?.statusCode === 400 && json?.message.includes('ALREADY_EXIST')) {
          let message = json?.message || 'Record already exist.';
          this.showMessage(message);
          return;
        }
        if (json?.errors?.length > 0) {
          const firstError = json?.errors[0];
          const errorMessage: any =
            typeof Object.values(firstError?.constraints)[0] === 'string'
              ? Object.values(firstError?.constraints)[0]
              : 'Something went wrong';
          this.showMessage(errorMessage);
        } else if (json?.message) {
          if (json?.statusCode === 404) {
            this.showMessage('API ' + this.translate.instant('COMMON.VALIDATION.NOT_FOUND'));
          } else {
            this.showMessage(json?.message);
          }
        }
        // const errors = this.getErrorMessages(json);
        // if (errors) {
        //   this.showMessage(Array.isArray(errors) ? errors[0] : errors);
        // }
      } else {
        this.showMessage('Something went wrong');
      }
    }
  }
  // handleErrorMessages(json: any) {
  //   const errors = this.getErrorMessages(json);
  //   if (errors.length > 0) {
  //     this.showMessage(Array.isArray(errors) ? errors[0] : errors);
  //   }
  // }

  /**
   * Displays a message using the toastService based on the provided type.
   *
   * @param {string} msg - The message to be displayed.
   * @param {string} [type='error'] - The type of message to display. Can be 'error', 'warning', 'info', or any other value for success.
   * @param {boolean} [textCapitalize=true] - Whether to capitalize the message text.
   * @return {void} This function does not return a value.
   */
  showMessage(msg: string, type = 'error', textCapitalize = true): void {
    let toastClasses = ['sid-toastr-text', textCapitalize ? '' : 'no-capitalize'];
    switch (type) {
      case 'error':
        this.toastService.error(msg, 'Error', {timeOut: 3000, messageClass: toastClasses.join(' ') });
        break;
      case 'warning':
        this.toastService.warning(msg, 'Warning', { timeOut: 3000,messageClass: toastClasses.join(' ') });
        break;
      case 'info':
        this.toastService.info(msg, 'Info', { timeOut: 3000,messageClass: toastClasses.join(' ') });
        break;
      default:
        this.toastService.success(msg, 'Success', { timeOut: 3000,messageClass: toastClasses.join(' ') });
        break;
    }
  }

  /**
   * Rejects the error messages from the errorData object.
   *
   * @param {any} errorData - The error data object.
   * @param {any} reject - The reject function.
   * @return {Promise<void>} A promise that resolves when the error messages are rejected.
   */
  rejectErrorMessages(errorData: any, reject: any) {
    this.getErrorMessages(errorData.error).then((errorsArray: any) => {
      if (errorsArray) {
        reject(errorsArray);
      }
    });
  }

  /**
   * Returns the HttpClient instance.
   *
   * @return {HttpClient} The HttpClient instance.
   */
  getHttpClient(): HttpClient {
    return this.http;
  }
}
