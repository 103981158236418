import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as CryptoJS from 'crypto-js';

import { TOKEN } from '../constants/system.constant';
import {REFRESH_TOKEN} from '@src/app/core/constants/system.constant';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public isServer: boolean = false;
  public isBrowser: boolean = false;
  public platformId: any;

  /**
   * Initializes the constructor of the class with the provided injector.
   *
   * @param {Injector} injector - The injector used to retrieve dependencies.
   */
  constructor(injector: Injector) {
    this.platformId = injector.get(PLATFORM_ID);
    this.isServer = isPlatformServer(this.platformId);
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  // @ts-ignore
  setDataInLocalStorage(payload: { key: string; value: any }, encrypted: boolean = false): void {
    if (this.isBrowser) {
      if (encrypted) {
        this.setEncryptData(payload);
      } else {
        localStorage.setItem(payload.key, JSON.stringify(payload.value));
      }
    }
  }

  /**
   * Retrieves data from local storage based on the provided key.
   *
   * @param {string} key - The key used to retrieve the data from local storage.
   * @param {boolean} [encrypted=false] - Indicates whether the data is encrypted.
   * @return {any} The retrieved data from local storage, or null if the data does not exist or an error occurs.
   */
  getDataInLocalStorage(key: string, encrypted: boolean = false): any {
    if (!this.isBrowser) {
      return null;
    }
    if (encrypted) {
      return this.getEncryptedData(key);
    }
    try {
      const item = localStorage?.getItem(key);
      if (item && item !== 'undefined') {
        return JSON.parse(item);
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  /**
   * Removes the data associated with the specified key from the local storage.
   *
   * @param {string} key - The key of the data to be removed.
   * @return {void} This function does not return anything.
   */
  removeDataInLocalStorage(key: string): void {
    if (this.isBrowser) {
      localStorage.removeItem(key);
    }
  }

  /**
   * Removes the data associated with the specified key from the local storage.
   *
   * @param {string} key - The key of the data to be removed.
   * @return {void} This function does not return anything.
   */
  remove(key: string): void {
    this.removeDataInLocalStorage(key);
  }

  /**
   * Retrieves data from local storage based on the provided key.
   *
   * @param {string} key - The key used to retrieve the data from local storage.
   * @param {boolean} [encrypted=false] - Indicates whether the data is encrypted.
   * @return {any} The retrieved data from local storage, or null if the data does not exist or an error occurs.
   */
  get(key: string, encrypted: boolean = false): any {
    return this.getDataInLocalStorage(key, encrypted);
  }

  // @ts-ignore
  set(payload: { key: string; value: any }, encrypted: boolean = false): any {
    this.setDataInLocalStorage(payload, encrypted);
  }

  /**
   * Clears all data from the local storage if the current environment is a browser.
   *
   * @return {any} The result of clearing the local storage, or undefined if the current environment is not a browser.
   */
  clearDataInLocalStorage(): any {
    if (this.isBrowser) {
      localStorage.clear();
    }
  }
  /**
   * Clears all items from local storage except for the ones specified in the `keepKeys` array.
   *
   * @param {string[] | null} keepKeys - An array of keys that should not be removed from local storage. If null, all items will be removed.
   */
  clearLocalStorageExceptOneKey(keepKeys: string[] | null) {
    if (keepKeys !== null) {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key !== null && !keepKeys.includes(key)) {
          // Check if the key is not null before removing it
          localStorage.removeItem(key);
        }
      }
    }
  }

  /**
   * Sets the token in local storage if the current environment is a browser and the token is not empty.
   *
   * @param {string} token - The token to be set in local storage.
   * @return {void} This function does not return anything.
   */
  setToken(token: string, refresh?:boolean): void {
    if(this.isBrowser && refresh){
      this.set({ key: REFRESH_TOKEN, value: token }, false);
    }
    if (this.isBrowser && token && !refresh) {
      this.set({ key: TOKEN, value: token }, false);
    }
  }

  /**
   * Retrieves the token from local storage if the current environment is a browser.
   *
   * @return {any} The token retrieved from local storage, or null if the current environment is not a browser.
   */
  getToken(refresh?:boolean): any {
    if (this.isBrowser) {
      if(refresh){
        
        return this.getDataInLocalStorage(REFRESH_TOKEN, false);
      }else{
        return this.getDataInLocalStorage(TOKEN, false);
      }
    }
    return null;
  }

  /**
   * Retrieves the encrypted data associated with the specified key from local storage.
   *
   * @param {string} key - The key used to retrieve the encrypted data.
   * @param {string | null} [secret=this.getToken()] - The secret used to decrypt the data. Defaults to the token retrieved from local storage.
   * @return {any} The decrypted data, or null if the data does not exist, an error occurs, or the current environment is not a browser.
   */
  getEncryptedData(key: string, secret: string | null = this.getToken()): any {
    try {
      if (!this.isBrowser || !secret) {
        return null;
      }
      const item = localStorage?.getItem(key);
      if (item && item !== 'undefined') {
        const bytes = CryptoJS.AES.decrypt(item, secret);
        const cryptoInFo = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(cryptoInFo);
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  //@ts-ignore
  setEncryptData(
    payload: { key: string; value: any },
    secret: string | null = this.getToken(),
  ): void {
    if (this.isBrowser && secret) {
      const cryptoInfo = CryptoJS.AES.encrypt(JSON.stringify(payload.value), secret).toString();
      localStorage.setItem(payload.key, cryptoInfo);
    }
  }

  /**
   * Encrypts the given payload using the AES encryption algorithm and returns the encrypted data as a string.
   *
   * @param {any} payload - The payload to be encrypted. Defaults to null.
   * @param {string | null} secret - The secret key used for encryption. Defaults to the value returned by the getToken() method.
   * @return {any} The encrypted data as a string, or null if encryption fails or the secret is not provided.
   */
  getEncryptedCryptoInfo(payload: any = null, secret: string | null = this.getToken()): any {
    try {
      if (secret && payload) {
        return CryptoJS.AES.encrypt(JSON.stringify(payload), secret).toString();
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  /**
   * Decrypts the given encrypted data using the AES decryption algorithm and returns the decrypted data as an object.
   *
   * @param {any} encryptData - The encrypted data to be decrypted. Defaults to an empty string.
   * @param {string | null} secret - The secret key used for decryption. Defaults to the value returned by the getToken() method.
   * @return {any} The decrypted data as an object, or null if decryption fails or the secret is not provided.
   */
  getDecryptedCryptoInfo(encryptData: any = '', secret: string | null = this.getToken()): any {
    try {
      if (secret && encryptData) {
        const bytes = CryptoJS.AES.decrypt(encryptData, secret);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
    } catch (e) {
      return null;
    }
  }
}
